import { motion, useAnimationControls } from "framer-motion";
import { useEffect } from "react";

interface TypeWriterProps {
  text: string;
  className?: string;
  delay?: number;
}

export function TypeWriter({ text, className = "", delay = 0 }: TypeWriterProps) {
  const controls = useAnimationControls();
  
  useEffect(() => {
    const sequence = async () => {
      await controls.start({
        opacity: 1,
        transition: { duration: 0 }
      });
      
      for (let i = 0; i <= text.length; i++) {
        await controls.start({
          width: `${i * 100 / text.length}%`,
          transition: { duration: 0.05 }
        });
      }
    };
    
    setTimeout(sequence, delay * 1000);
  }, [text, controls, delay]);

  return (
    <div className="relative">
      <span className={`${className} opacity-0`}>{text}</span>
      <motion.div
        className={`${className} absolute top-0 left-0 whitespace-nowrap overflow-hidden`}
        initial={{ width: "0%", opacity: 0 }}
        animate={controls}
      >
        {text}
      </motion.div>
    </div>
  );
}
