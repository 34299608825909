import { motion } from "framer-motion";
import { ScrollReveal } from "../animations/ScrollReveal";
import { TypeWriter } from "../animations/TypeWriter";

const GridPattern = () => (
  <svg className="absolute inset-0 w-full h-full opacity-[0.15]" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <pattern id="grid" width="40" height="40" patternUnits="userSpaceOnUse">
        <path d="M 40 0 L 0 0 0 40" fill="none" stroke="currentColor" strokeWidth="1"/>
      </pattern>
      <linearGradient id="fade" x1="0" y1="0" x2="1" y2="1">
        <stop offset="0%" stopColor="#6B46C1" stopOpacity="0.1"/>
        <stop offset="100%" stopColor="#3B82F6" stopOpacity="0.1"/>
      </linearGradient>
    </defs>
    <rect width="100%" height="100%" fill="url(#grid)" />
    <rect width="100%" height="100%" fill="url(#fade)" />
  </svg>
);

const FloatingShapes = () => (
  <div className="absolute inset-0 overflow-hidden">
    {[...Array(3)].map((_, i) => (
      <motion.div
        key={i}
        className="absolute"
        style={{
          width: Math.random() * 200 + 100,
          height: Math.random() * 200 + 100,
          borderRadius: '30% 70% 70% 30% / 30% 30% 70% 70%',
          background: `linear-gradient(45deg, ${i % 2 ? '#4B5563' : '#374151'}, transparent)`,
          left: `${Math.random() * 100}%`,
          top: `${Math.random() * 100}%`,
          opacity: 0.05,
        }}
        animate={{
          x: [0, 30, 0],
          y: [0, 20, 0],
          rotate: [0, 180, 0],
        }}
        transition={{
          duration: 20 + i * 5,
          repeat: Infinity,
          ease: "linear"
        }}
      />
    ))}
  </div>
);

const FeatureCard = ({ title, description }: { title: string; description: string }) => (
  <motion.div 
    className="p-6 bg-white bg-opacity-10 backdrop-blur-sm rounded-xl border border-white border-opacity-20"
    whileHover={{ y: -5, scale: 1.02 }}
    transition={{ duration: 0.2 }}
  >
    <h3 className="text-xl font-semibold text-gray-200 mb-2">{title}</h3>
    <p className="text-base text-gray-300">{description}</p>
  </motion.div>
);

export function Hero() {
  return (
    <section className="min-h-screen flex items-center justify-center relative overflow-hidden bg-gradient-to-br from-gray-950 via-gray-900 to-gray-800">
      <GridPattern />
      <FloatingShapes />
      
      <div className="container mx-auto px-4 py-20 relative z-10">
        <ScrollReveal>
          <motion.div
            className="text-center max-w-4xl mx-auto"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <div className="text-3xl md:text-4xl font-bold mb-6 text-white leading-tight max-w-[300px] sm:max-w-none mx-auto">
              <TypeWriter
                text="Building the Future of Web3"
                className="hidden md:block bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-blue-400"
                delay={0}
              />
              <div className="block md:hidden">
                <TypeWriter
                  text="Building the"
                  className="block bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-blue-400"
                  delay={0.2}
                />
                <TypeWriter
                  text="Future of Web3"
                  className="block bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-blue-400"
                  delay={0.9}
                />
              </div>
            </div>
            <motion.p 
              className="text-lg text-gray-300 mb-12"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 1.6 }}
            >
              We turn your Web3 ideas into reality - fast, simple, and affordable. From smart contracts to dApps, we build everything you need to succeed in the blockchain space.
            </motion.p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
              <FeatureCard
                title="Rapid Prototyping"
                description="From concept to working prototype in days, not months"
              />
              <FeatureCard
                title="Smart Development"
                description="Expert blockchain developers at your service"
              />
              <FeatureCard
                title="Cost Effective"
                description="Premium quality at half the usual cost"
              />
            </div>
          </motion.div>
        </ScrollReveal>
      </div>
    </section>
  );
}
