import { Hero } from "@/components/sections/Hero";
import { ClientSegments } from "@/components/sections/ClientSegments";
import { Benefits } from "@/components/sections/Benefits";
import { Contact } from "@/components/sections/Contact";
import { Navbar } from "@/components/layout/Navbar";

export default function Home() {
  return (
    <main className="min-h-screen bg-gray-900">
      <Navbar />
      <Hero />
      <ClientSegments />
      <Benefits />
      <Contact />
    </main>
  );
}
