import { motion } from "framer-motion";
import { ScrollReveal } from "../animations/ScrollReveal";
import { Zap, DollarSign, Lightbulb } from "lucide-react";
import { Card, CardHeader } from "@/components/ui/card";

const benefits = [
  {
    title: "Built in Half the Time",
    description: "Get your project up and running twice as fast. We make things happen quickly without cutting corners.",
    icon: Zap,
    gradient: "from-purple-500 to-blue-500"
  },
  {
    title: "Save 50% on Costs",
    description: "Pay less than half of what traditional Web3 agencies charge. Quality doesn't have to break the bank.",
    icon: DollarSign,
    gradient: "from-blue-500 to-indigo-500"
  },
  {
    title: "Simple & Straightforward",
    description: "Complex blockchain concepts explained simply - no technical jargon, just clear communication that makes sense.",
    icon: Lightbulb,
    gradient: "from-indigo-500 to-purple-500"
  }
];

export function Benefits() {
  return (
    <section className="py-20 bg-gray-900 relative overflow-hidden">
      {/* Geometric Background Pattern */}
      <div className="absolute inset-0 opacity-5">
        <svg className="w-full h-full" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <pattern id="grid" width="10" height="10" patternUnits="userSpaceOnUse">
            <path d="M 10 0 L 0 0 0 10" fill="none" stroke="currentColor" strokeWidth="0.5"/>
          </pattern>
          <rect width="100" height="100" fill="url(#grid)" />
        </svg>
      </div>

      <div className="container mx-auto px-4 relative z-10">
        <ScrollReveal>
          <motion.h2 
            className="text-3xl font-bold text-center mb-16 text-gray-100"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
          >
            Why Choose Us
          </motion.h2>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {benefits.map((benefit, index) => (
              <ScrollReveal key={benefit.title} delay={index * 0.2}>
                <motion.div
                  whileHover={{ translateY: -5 }}
                  transition={{ duration: 0.3 }}
                >
                  <Card className="h-full shadow-lg hover:shadow-xl transition-shadow duration-300 bg-gray-800 border-gray-700">
                    <CardHeader className="p-6">
                      <div className={`w-16 h-16 rounded-lg mb-6 flex items-center justify-center bg-gradient-to-br ${benefit.gradient}`}>
                        <benefit.icon className="w-6 h-6 text-white" />
                      </div>
                      <h3 className="text-xl font-semibold mb-4 text-gray-100">{benefit.title}</h3>
                      <p className="text-base text-gray-300">{benefit.description}</p>
                    </CardHeader>
                  </Card>
                </motion.div>
              </ScrollReveal>
            ))}
          </div>
        </ScrollReveal>
      </div>
    </section>
  );
}
