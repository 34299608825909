import { Card, CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import { ScrollReveal } from "../animations/ScrollReveal";
import { Code, Users, Building2, Brain, Lock, Sparkles } from "lucide-react";
import { motion } from "framer-motion";

const segments = [
  {
    title: "Entrepreneurs",
    description: "Looking to integrate crypto into their existing applications or launch new blockchain-based ventures.",
    icon: Sparkles
  },
  {
    title: "Professionals",
    description: "With innovative Web3 app ideas but lacking the technical expertise to bring them to life.",
    icon: Users
  },
  {
    title: "Businesses",
    description: "Ready to transition from Web2 to Web3 and unlock new opportunities in the blockchain space.",
    icon: Building2
  },
  {
    title: "Developers",
    description: "Interested in building fun and engaging blockchain applications with modern tools.",
    icon: Code
  },
  {
    title: "Stealth Projects",
    description: "Building confidential Web3 projects that require expertise and discretion.",
    icon: Lock
  },
  {
    title: "Beginners",
    description: "Those who want to start their Web3 journey but don't know where to begin",
    icon: Brain
  }
];

export function ClientSegments() {
  return (
    <section className="py-20 bg-gray-900">
      <div className="container mx-auto px-4">
        <ScrollReveal>
          <motion.h2 
            className="text-3xl font-bold text-center mb-4 text-gray-100"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
          >
            Who We Serve
          </motion.h2>
          <p className="text-lg text-center text-gray-300 mb-12">
            Our expertise serves a diverse range of clients looking to make their mark in the Web3 space.
          </p>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {segments.map((segment, index) => (
              <ScrollReveal key={segment.title} delay={index * 0.1}>
                <Card className="h-full hover:shadow-lg transition-shadow duration-300 min-h-[200px] bg-gray-800 border-gray-700">
                  <CardHeader className="h-full flex flex-col p-6">
                    <div className="flex-grow-0 mb-4">
                      <segment.icon className="w-8 h-8 text-purple-400" />
                    </div>
                    <CardTitle className="mb-3 leading-tight text-xl text-gray-100">{segment.title}</CardTitle>
                    <CardDescription className="flex-grow text-base text-gray-300">{segment.description}</CardDescription>
                  </CardHeader>
                </Card>
              </ScrollReveal>
            ))}
          </div>
        </ScrollReveal>
      </div>
    </section>
  );
}
