import { ScrollReveal } from "../animations/ScrollReveal";
import { motion } from "framer-motion";
import { Rocket } from "lucide-react";

const GeometricShapes = () => (
  <div className="absolute inset-0 overflow-hidden pointer-events-none">
    {[...Array(3)].map((_, i) => (
      <motion.div
        key={i}
        className="absolute"
        style={{
          width: Math.random() * 100 + 50,
          height: Math.random() * 100 + 50,
          borderRadius: '30% 70% 70% 30% / 30% 30% 70% 70%',
          background: `linear-gradient(45deg, ${i % 2 ? '#1F2937' : '#111827'}, transparent)`,
          left: `${Math.random() * 100}%`,
          top: `${Math.random() * 100}%`,
          opacity: 0.1,
        }}
        animate={{
          x: [0, 20, 0],
          y: [0, 15, 0],
          rotate: [0, 180, 0],
        }}
        transition={{
          duration: 15 + i * 5,
          repeat: Infinity,
          ease: "linear"
        }}
      />
    ))}
  </div>
);

export function Contact() {
  return (
    <section className="py-20 bg-gray-900 relative">
      <GeometricShapes />
      <div className="container mx-auto px-4 max-w-4xl">
        <ScrollReveal>
          <motion.h2 
            className="text-3xl font-bold text-center mb-4 text-gray-100"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
          >
            Start Building Now
          </motion.h2>
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            whileHover={{ 
              scale: 1.02,
              boxShadow: "0 10px 30px -10px rgba(0, 0, 0, 0.5)"
            }}
            className="p-8 md:p-12 bg-gray-800/30 backdrop-blur-sm rounded-xl border border-gray-700/50 relative overflow-hidden"
          >
            <div className="absolute inset-0 bg-gradient-to-br from-gray-800/20 to-gray-900/20" />
            <div className="relative z-10 flex flex-col items-center">
              <div className="w-16 h-16 rounded-lg mb-6 flex items-center justify-center bg-gradient-to-br from-purple-500 to-blue-500">
                <Rocket className="w-8 h-8 text-white" />
              </div>
              <p className="text-lg text-gray-300 max-w-lg text-center">
                Drop us a line at contact@angulabs.com for a friendly chat about your idea - no strings attached!
              </p>
            </div>
          </motion.div>
        </ScrollReveal>
      </div>
    </section>
  );
}
